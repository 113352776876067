<template>
    <div class="row">
        <div class="col-12">
            <div class="p-3">
                <!-- modal for add new price record -->
                <b-overlay :show="add_price_show_overlay" rounded="sm">
                    <b-modal
                        id="modify_location"
                        cancel-variant="secondary"
                        ok-title="Modify Location"
                        cancel-title="Cancel"
                        centered
                        title="Modify ip location"
                        @ok="modifyIpLocation"
                    >
                        <b-form>
                            <b-form-group label="Location" label-for="countryCode">
                                <v-select
                                    id="countryCode"
                                    label="text"
                                    :reduce="(text) => text.value"
                                    v-model="modifyCountryCode"
                                    :options="CountryOption.slice(1)"
                                />
                            </b-form-group>
                        </b-form>
                    </b-modal>
                </b-overlay>
            </div>
        </div>

        <div class="col-12">
            <b-card header="Node Statistics">
                <div class="pr-3 pb-3 pl-3">
                    <div>{{GetFullContinent("EU")+": "}} {{continentNodeCount["EU"]?continentNodeCount["EU"]:0}}</div>
                    <div>{{GetFullContinent("AS")+": "}} {{continentNodeCount["AS"]?continentNodeCount["AS"]:0}}</div>
                    <div>{{GetFullContinent("NA")+": "}} {{continentNodeCount["NA"]?continentNodeCount["NA"]:0}}</div>
                    <div>{{GetFullContinent("SA")+": "}} {{continentNodeCount["SA"]?continentNodeCount["SA"]:0}}</div>
                    <div>{{GetFullContinent("AF")+": "}} {{continentNodeCount["AF"]?continentNodeCount["AF"]:0}}</div>
                    <div>{{GetFullContinent("OC")+": "}} {{continentNodeCount["OC"]?continentNodeCount["OC"]:0}}</div>

                    <div>Total: {{continentNodeCount["EU"]+continentNodeCount["AS"]+continentNodeCount["NA"]+continentNodeCount["SA"]+continentNodeCount["AF"]+continentNodeCount["OC"]}}</div>
                </div>
            </b-card>
        </div>

        <!-- table -->
        <div class="col-12 mt-3">
            <b-card header="Nodes">
                <div class="pr-3 pb-3 pl-3">
                    <b-row class="mb-3">
                        <b-col>
                            <VDropdown v-if="$store.state.auth.my_roles.includes('admin')||$store.state.auth.my_roles.includes('read_only')">
                                <b-button variant="secondary" class="mr-3">
                                    <feather-icon icon="SearchIcon" class="mr-2" size="15" />
                                    <span class="align-middle">Search-items</span>
                                </b-button>
                                <!-- This will be the content of the popover -->
                                <template #popper>
                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <b-form-input placeholder="node ip" v-model="queryCondition.ip" />
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <b-form-input placeholder="user id" v-model="queryCondition.userId" />
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <v-select
                                                id="continentCode"
                                                label="text"
                                                :reduce="(text) => text.value"
                                                placeholder="continent"
                                                v-model="queryCondition.continentCode"
                                                :options="ContinentOption"
                                            />
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <v-select
                                                id="countryCode"
                                                label="text"
                                                :reduce="(text) => text.value"
                                                placeholder="location"
                                                v-model="queryCondition.countryCode"
                                                :options="CountryOption"
                                            />
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-1 mt-3">
                                        <b-col>
                                            <b-button variant="secondary" v-close-popper="true" @click="search"> Search</b-button>
                                            <b-button class="ml-2" variant="secondary" @click="clearQueryCondition"> Clear</b-button>
                                        </b-col>
                                    </b-row>
                                </template>
                            </VDropdown>

                            <b-button variant="secondary" @click="search"><feather-icon icon="RotateCcwIcon" size="15" /></b-button>
                        </b-col>
                    </b-row>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            ref="remote_q_table"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRows"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :select-options="{
                                enabled: true,
                            }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'pages',
                                perPage: 10,
                                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000],
                                setCurrentPage: 1,
                            }"
                        >
                            <div slot="selected-row-actions">
                                <b-button class="ml-3" v-b-modal.modify_location>modify ip location</b-button>
                            </div>
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: Common -->

                                <span v-if="props.column.field === 'country_code'">
                                    {{ props.row["country_code"] === ALL_Country ? ALL_Country : GetFullCountry(props.row["country_code"]) }}
                                </span>

                                <span v-else-if="props.column.field === 'cache_space_rate'">
                                    <b-progress max="100">
                                        <b-progress-bar
                                            :value="props.row.cache_space_rate"
                                            :variant="cache_space_variant(props.row.cache_space_rate)"
                                            :label="`${props.row.cache_space_rate ? props.row.cache_space_rate.toFixed(2) : 0}%`"
                                        ></b-progress-bar>
                                    </b-progress>
                                </span>

                                <span v-else-if="props.column.field === 'bandwidth_bytes_sec'">
                                    {{ ((props.row.bandwidth_bytes_sec * 8) / (1024 * 1024)).toFixed(2) }} Mb/s
                                </span>

                                <span v-else-if="props.column.field === 'status'">
                                    <b-badge v-if="props.row.status === 'ON'" pill variant="success" class="mr-3">ON</b-badge>
                                    <b-badge v-else pill class="mr-3">DOWN</b-badge>
                                </span>

                                <span v-else-if="props.column.field === 'version'">
                                    <b-badge
                                        v-if="$tools.version.versionCompare(props.row.version, latestVersion) >= 0"
                                        v-b-tooltip.hover.v-success
                                        title="latest version"
                                        pill
                                        variant="success"
                                        class="mr-3"
                                        >{{ props.row.version }}</b-badge
                                    >
                                    <b-badge
                                        v-else-if="$tools.version.versionCompare(props.row.version, allowVersion) >= 0"
                                        v-b-tooltip.hover.v-warning
                                        title="new version available"
                                        pill
                                        variant="warning"
                                        class="mr-3"
                                        >{{ props.row.version }}</b-badge
                                    >
                                    <b-badge
                                        v-else
                                        v-b-tooltip.hover.v-danger
                                        title="deprecated version, please download new version"
                                        pill
                                        variant="danger"
                                        class="mr-3"
                                        >{{ props.row.version }}</b-badge
                                    >
                                </span>

                                <span v-else-if="props.column.field === 'action1'">

                                    <!-- <b-button class="ml-1" variant="primary" @click.stop="gotoStorageConsole(props.row.originalIndex)"
                                        >Storage Console</b-button
                                    > -->

                                    <b-button
                                        v-if="$store.state.auth.my_roles.includes('admin')"
                                        class="ml-1"
                                        variant="primary"
                                        @click.stop="sendSpeedTestCmd(props.row.originalIndex)"
                                        >test speed</b-button
                                    >
                                </span>

                                <span v-else>
                                    {{ props.row[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>

                    <!-- modal_row_view_update-->
                    <!-- <b-modal id="modal_row_view_update" @hide="handleHide" title="view/edit" cancel-variant="secondary" cancel-title="Close" centered>
                        <b-overlay :show="modal_row_view_overlay" rounded="sm">
                            <b-form>
                                <b-form-group label="id" label-for="id">
                                    <b-form-input id="id" placeholder="id" v-model="this.focus.id" disabled />
                                </b-form-group>

                                <b-form-group label="Continent" label-for="continentCode">
                                    <v-select
                                        id="continentCode"
                                        label="text"
                                        :reduce="(text) => text.value"
                                        v-model="newPrice.continentCode"
                                        :options="ContinentOption"
                                        @input="continentInput"
                                    />
                                </b-form-group>
                                <b-form-group label="location" label-for="countryCode">
                                    <v-select
                                        id="countryCode"
                                        label="text"
                                        :reduce="(text) => text.value"
                                        v-model="newPrice.countryCode"
                                        :options="CountryOption"
                                        @input="countryInput"
                                    />
                                </b-form-group>

                                <b-form-group label="Price $/GB" label-for="price">
                                    <b-form-input id="pdnPerByte" placeholder="price $/GB" v-model="newPrice.pdnPerByte" />
                                </b-form-group>

                                <b-form-group label="UserId" label-for="userId">
                                    <b-form-input id="userId" placeholder="user id" v-model="newPrice.userId" disabled />
                                </b-form-group>
                            </b-form>
                        </b-overlay>

                        <template #modal-footer>
                            <b-row class="w-100">
                                <b-col class="text-left">
                                    <b-button variant="danger" @click="deletePriceRecord">Delete</b-button>
                                </b-col>
                                <b-col cols="8" class="text-right">
                                    <b-button variant="primary" class="mr-3" @click="updatePrice">Update</b-button>
                                    <b-button variant="secondary" @click="$bvModal.hide('modal_row_view_update')">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </template>
                    </b-modal> -->
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormFile,
    BFormTags,
    BFormTextarea,
    BProgress,
    BProgressBar,
    VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";

import {
    ALL_Continent,
    ALL_Country,
    CountryMap,
    ContinentMap,
    GetFullCountry,
    GetFullContinent,
    ContinentOption,
    CountryOption,
} from "../../location/location";

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormFile,
        BFormTags,
        BFormTextarea,
        BProgress,
        BProgressBar,
        VBTooltip,
        VueGoodTable,
        vSelect,
    },

    methods: {
        GetFullCountry,
        GetFullContinent,
        continentInput(code) {
            if (code === null || code === "") {
                this.newPrice.continentCode = this.ALL_Continent;
                this.newPrice.countryCode = this.ALL_Country;
                return;
            }
            if (code === this.ALL_Continent) {
                this.newPrice.countryCode = this.ALL_Country;
                return;
            }
            if (this.newPrice.countryCode === this.ALL_Country) {
                return;
            }
            let currentCountryCode = this.newPrice.countryCode;
            let countryInfo = CountryMap[currentCountryCode];
            let currentContinentCode = code;
            if (countryInfo.ContinentCode !== currentContinentCode) {
                this.newPrice.countryCode = this.ALL_Country;
            }
        },
        countryInput(code) {
            if (code === null || code === "") {
                this.newPrice.countryCode = this.ALL_Country;
                return;
            }
            if (code === this.ALL_Country) {
                return;
            }
            let countryCodeInput = code;
            let countryInfo = CountryMap[countryCodeInput];
            let continentCode = countryInfo.ContinentCode;
            this.newPrice.continentCode = continentCode;
        },

        clickAddPriceRecord() {
            this.newPrice = {
                continentCode: ALL_Continent,
                countryCode: ALL_Country,
                pdnPerByte: 0,
                userId: 0,
            };
        },
        clearQueryCondition() {
            this.queryCondition = {
                ip: null,
                userId: null,
                continentCode: null,
                countryCode: null,
            };
        },

        onPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },

        open_row_view_edit(rowid) {
            // this.focus = this.row_data[rowid];
            // this.newPrice.continentCode = this.focus.continent_code === this.ALL_Continent ? this.ALL_Continent : this.focus.continent_code;
            // this.newPrice.countryCode = this.focus.country_code === this.ALL_Country ? this.ALL_Country : this.focus.country_code;
            // this.newPrice.userId = this.focus.user_id;
            // this.newPrice.pdnPerByte = (this.focus.pdn_per_byte / 1000).toFixed(3);
            // this.$bvModal.show("modal_row_view_update");
        },

        handleHide(bvModalEvt) {
            if (this.remote_updating) {
                bvModalEvt.preventDefault();
            }
        },

        async search() {
            this.$refs["remote_q_table"].reset();
            this.offset = 0;
            await this.refreshTable();
        },

        cache_space_variant(rate) {
            if (rate <= 50) {
                return "success";
            } else if (rate <= 80) {
                return "warning";
            } else {
                return "danger";
            }
        },

        async refreshTable() {
            this.table_show_overlay = true;
            let ip = this.queryCondition.ip ? [this.queryCondition.ip] : null;
            let userId = this.queryCondition.userId ? parseInt(this.queryCondition.userId) : null;
            let continent = this.queryCondition.continentCode;
            let country = this.queryCondition.countryCode;

            let resp = await this.$api.node.queryNode(ip, userId, continent, country, this.limit, this.offset, this.$store.state.auth.my_web_token);
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let nodes = resp.result.node_list;

            for (let i = 0; i < nodes.length; i++) {
                let spacePercent = (nodes[i].stor_used_bytes / nodes[i].stor_total_bytes) * 100;
                if (spacePercent < 0) {
                    nodes[i].cache_space_rate = 0;
                } else if (spacePercent > 100) {
                    nodes[i].cache_space_rate = 100;
                } else {
                    nodes[i].cache_space_rate = spacePercent;
                }
            }

            this.row_data = nodes;
            this.totalRows = resp.result.count;
        },

        async sendSpeedTestCmd(rowid) {
            // console.log("send speed test cmd", this.row_data[rowid].ip);
            let resp = await this.$api.speedTest.speedTestCmd([this.row_data[rowid].ip], this.$store.state.auth.my_web_token);

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("test cmd sent", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });
            return;
        },

        async modifyIpLocation() {
            let newCountryCode = this.modifyCountryCode;
            if (newCountryCode === null || newCountryCode === "" || newCountryCode === this.ALL_Country) {
                this.$bvToast.toast("location error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let rows = this.$refs["remote_q_table"].selectedRows;
            let ips = [];
            for (let i = 0; i < rows.length; i++) {
                ips.push(rows[i].ip);
            }

            let resp = await this.$api.node.modifyIpLocation(ips, newCountryCode, this.$store.state.auth.my_web_token);

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("ip location modified", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });

            this.refreshTable();
        },

        gotoStorageConsole(rowid) {
            // console.log("go to storage console", this.row_data[rowid].ip);
            let tag = this.$tools.ipTag.ipToTag(this.row_data[rowid].ip);
            let url = "https://spec00-" + tag + ".mesontracking.com:" + this.row_data[rowid].storage_port;
            // console.log("go to storage console", url);
            window.open(url, "_blank");
        },

        async getVersionInfo() {
            let resp = await this.$api.node.version();
            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.allowVersion = resp.result.allow_version;
            this.latestVersion = resp.result.latest_version;
        },

        async getContinentNodeCount() {

            let resp = await this.$api.node.queryNodeCount(["EU","AS","NA","SA","AF","OC"],[]);
            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.continentNodeCount = resp.result.continent_count;
        },
    },

    mounted() {
        this.getVersionInfo();
        this.getContinentNodeCount();
        this.refreshTable();
    },
    data() {
        return {
            //for table
            columns: [
                {
                    label: "IP",
                    field: "ip",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Port",
                    field: "port",
                    tdClass: "text-center",
                    thClass: "text-center",
                },

                {
                    label: "Location",
                    field: "country_code",
                    tdClass: "text-center",
                    thClass: "text-center",
                },

                {
                    label: "Space Usage",
                    field: "cache_space_rate",
                    tdClass: "text-center",
                    thClass: "text-center",
                },

                {
                    label: "Bandwidth(Mb/s)",
                    field: "bandwidth_bytes_sec",
                    tdClass: "text-center",
                    thClass: "text-center",
                },

                {
                    label: "Node Credit",
                    field: "node_credit",
                    tdClass: "text-center",
                    thClass: "text-center",
                },

                {
                    label: "User ID",
                    field: "userid",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Version",
                    field: "version",
                    tdClass: "text-center",
                    thClass: "text-center",
                },

                {
                    label: "Status",
                    field: "status",
                    tdClass: "text-center",
                    thClass: "text-center",
                },

                {
                    label: "Action",
                    field: "action1",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
            ],
            row_data: [],
            limit: 10,
            offset: 0,
            totalRows: 0,

            //for update and delete
            focus: {},

            table_show_overlay: true,
            modal_row_view_overlay: false,
            add_price_show_overlay: false,

            queryCondition: {
                ip: null,
                userId: null,
                continentCode: "NA",
                countryCode: null,
            },

            ALL_Continent: ALL_Continent,
            ALL_Country: ALL_Country,
            CountryMap: CountryMap,
            ContinentMap: ContinentMap,
            ContinentOption: ContinentOption,
            CountryOption: CountryOption,

            latestVersion: "",
            allowVersion: "",

            modifyCountryCode: "",

            continentNodeCount: {},
        };
    },
};
</script>
